@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');


.container {
    position: relative;
    width: 100%;
    background: #fff;
    margin: 0 15px;
    padding: 10px 20px;
    border-radius: 7px;
}

.container .skill-box {
    width: 100%;
    margin: 25px 0;
}

.skill-box .title {
    text-align: left;
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
}

.skill-box .skill-bar {
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0, 0, 0, 0.1);
}

.skill-box .skill-per {
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 6px;
    background: #333333;
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
}

@keyframes progress {
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skill-per .tooltip {
    top: -2.2rem;
    right: -1.2rem;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    background: #333333;
    z-index: 1;
}

.tooltip::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    height: 1rem;
    width: 1rem;
    z-index: -1;
    background-color: #333333;
    transform: translateX(-50%) rotate(45deg);
}
