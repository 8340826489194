nav, .nav-links {
    display: flex;
}

nav {
    justify-content: space-around;
    align-items: center;
    height: 17vh;
}

footer {
    height: 26vh;
    margin: 0 1rem;
}

footer p {
    text-align: center;
}

.nav-footer {
    .nav-links {
        gap: 2rem;
        list-style: none;
        font-size: 1.5rem;
    }

    a {
        color: black;
        text-decoration: none;
        text-decoration-color: white;
    }

    a:hover {
        color: grey;
        text-decoration: underline;
        text-underline-offset: 1rem;
        text-decoration-color: rgb(181, 181, 181);
    }
}

@media screen and (max-width: 1200px) {
    footer {
        height: 40vh;
    }

    footer nav {
        height: fit-content;
        margin-bottom: 2rem;
    }
}
