@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
    margin: 0;
    padding: 0;
}

body {
    --color: rgba(30, 30, 30);
    --bgColor: white;
    font-family: "Poppins", sans-serif;
}

html {
    scroll-behavior: smooth;
}

p {
    color: rgb(85, 85, 85);
}

img {
    max-width: none;
}

a,
.btn {
    transition: all 300ms ease;
}

section {
    padding-top: 4vh;
    padding-left: 2vh;
    padding-right: 2vh;
    min-height: 100vh;
    box-sizing: border-box;
}

.section-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.section-picture-container {
    display: flex;
    height: 400px;
    width: 400px;
    margin: auto 0;
}

.section-text {
    align-self: center;
    text-align: center;
}

.section-text p {
    font-weight: 600;
}

.section-text-p2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.section-title-h1 {
    font-size: 3rem;
    text-align: center;
}

.about-details-container {
    justify-content: center;
    flex-direction: column;
}

.about-containers,
.about-details-container {
    display: flex;
}


.icon-rotate180 {
    transform: rotate(180deg);
}

.arrow-top {
    position: fixed;
    right: 1rem;
    top: 0.5rem;
}

.arrow-bottom {
    position: fixed;
    right: 1rem;
    bottom: 0.5rem;
}

.details-container {
    padding: 1.5rem;
    flex: 1;
    background: white;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    text-align: center;
}

.section-container {
    gap: 4rem;
    height: 80%;
}

.section-picture-container {
    height: 400px;
    width: 400px;
    margin: auto 0;
}

.article-container {
    display: flex;
    text-align: initial;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5rem;
    justify-content: space-around;
}

article {
    display: flex;
    width: 10rem;
    gap: 0.5rem;
}

article .icon {
    cursor: default;
}

#certificates, #videos, #contact, #about {
    position: relative;
}

.email-icon {
    height: 2.5rem;
}

@media screen and (max-width: 1400px) {
    .about-containers {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1200px) {

    .section-container {
        display: block;
    }

    .arrow {
        display: none;
    }

    section,
    .section-container {
        height: fit-content;
    }

    .section-picture-container {
        width: 275px;
        height: 275px;
        margin: 0 auto 2rem;
    }

    .about-containers {
        margin-top: 0;
    }
}

@media screen and (max-width: 600px) {

    .contact-container {
        height: 40vh;
    }

    article {
        font-size: 1rem;
    }

    .about-containers,
    .contact-info-upper-container,
    .btn-container {
        flex-wrap: wrap;
    }

    .contact-info-container {
        margin: 0;
    }

    .contact-info-container p,
    .nav-links li a {
        font-size: 1rem;
    }

    .logo {
        font-size: 1.5rem;
    }

    .nav-links {
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
    }

    .section-picture-container {
        width: auto;
        height: 46vw;
        justify-content: center;
    }

    .section-text-p2 {
        font-size: 1.25rem;
    }

}


.pl {
    display: block;
    width: 6.25em;
    height: 6.25em;
}

.pl__ring, .pl__ball {
    animation: ring 2s ease-out infinite;
}

.pl__ball {
    animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 10%);
        --fg: hsl(var(--hue), 10%, 90%);
    }
}

/* Animation */
@keyframes ring {
    from {
        stroke-dasharray: 0 257 0 0 1 0 0 258;
    }
    25% {
        stroke-dasharray: 0 0 0 0 257 0 258 0;
    }
    50%, to {
        stroke-dasharray: 0 0 0 0 0 515 0 0;
    }
}

@keyframes ball {
    from, 50% {
        animation-timing-function: ease-in;
        stroke-dashoffset: 1;
    }
    64% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -109;
    }
    78% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -145;
    }
    92% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -157;
    }
    57%, 71%, 85%, 99%, to {
        animation-timing-function: ease-out;
        stroke-dashoffset: -163;
    }
}

.wrapper {
    position: fixed;
    bottom: 2rem;
    right: -375px;
    max-width: 345px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 15px 25px 22px;
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.wrapper.show {
    right: 3rem;
}

.wrapper header {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

header h2 {
    color: rgb(53, 53, 53);
    font-weight: 500;
}

.wrapper .data {
    margin-top: 16px;
}

.wrapper .data p {
    color: #333;
    font-size: 16px;
}

.data p a {
    color: rgb(53, 53, 53);
    text-decoration: none;
}

.data p a:hover {
    text-decoration: underline;
}

.wrapper .buttons {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons .button {
    border: none;
    color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    background: rgb(53, 53, 53);
    cursor: pointer;
    width: calc(100% / 2 - 10px);
    transition: all 0.2s ease;
}
