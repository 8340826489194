nav, .nav-links {
    display: flex;
}

nav {
    justify-content: space-between;
    align-items: center;
    height: 17vh;
    padding-left: 2rem;
    padding-right: 2rem;
}

#hamburger-nav {
    display: none;
}

.nav-header {
    .nav-links {
        gap: 2rem;
        list-style: none;
        font-size: 1.5rem;
    }

    a {
        color: black;
        text-decoration: none;
        text-decoration-color: white;
    }

    a:hover {
        color: grey;
        text-decoration: underline;
        text-underline-offset: 1rem;
        text-decoration-color: rgb(181, 181, 181);
    }

    .logo {
        font-size: 2rem;
    }

    .logo:hover {
        color: grey;
        cursor: default;
    }

    .hamburger-menu {
        position: relative;
        display: inline-block;
    }

    .hamburger-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 24px;
        width: 30px;
        cursor: pointer;
    }

    .hamburger-icon span {
        width: 100%;
        height: 2px;
        background-color: black;
        transition: all 0.3s ease-in-out;
    }

    .hamburger-icon span:first-child {
        transform: none;
    }

    .menu-links {
        position: absolute;
        top: 100%;
        left: -130px;
        background-color: rgb(250, 250, 250, 0.7);
        width: fit-content;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        z-index: 100;
    }

    .menu-links a {
        display: block;
        padding: 10px;
        text-align: center;
        font-size: 1.5rem;
        color: black;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }

    .menu-links a:hover {
        color: rgb(100, 100, 100);
    }

    .menu-links li {
        list-style: none;
    }

    .menu-links.open {
        max-height: 500px;
    }

    .hamburger-icon.open span:first-child {
        transform: rotate(45deg) translate(10px, 5px);
    }

    .hamburger-icon.open span:nth-child(2) {
        opacity: 0;
    }

    .hamburger-icon.open span:last-child {
        transform: rotate(-45deg) translate(10px, -5px);
    }
}

@media screen and (max-width: 1200px) {
    #desktop-nav {
        display: none;
    }

    #hamburger-nav {
        display: flex;
    }
}
